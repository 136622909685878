.title span{
   font-family: 'SF Pro Text';
   font-style: normal;
   font-weight: bold;
   font-size: 16px;
   line-height: 19px;
   color: #1C68AD;
}
@media (min-width: 800px) {
   .title{
      font-size: 15px;
      padding-left: 4px;
   }
}
@media (min-width: 992px) {
   .title{
      font-size: 16px;
   }
}