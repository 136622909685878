.title-header{
   font-family: 'SF Pro Text';
   font-style: normal;
   font-weight: bold;
   font-size: 24px;
   line-height: 29px;
   text-align: center;   
   color: #1C68AD;
   margin-bottom: 33px;
}

.pesquisar{
   font-family: 'Roboto'; 
   font-style: normal;
   font-weight: normal;
   font-size: 19px;
   line-height: 12px;
   display: flex;
   align-items: center;
   letter-spacing: 0.15px;
   color: #007DFF;
   position: relative;
   top: 10%;
}

/* .input{
   border: none; 
   border-bottom: 2px solid #007DFF; 
   border-radius: 0; 
} */
.icon-search{
   color: #007DFF;
   position: absolute;
   right: 4%;
   top: 42px;
   font-size: 18px; 
}

.buttons{
   margin-bottom: 24px;
   text-align: end;
}

.button-limpar{
   text-transform: uppercase;
   width: 150px;
   height: 36px; 
}

.button-limpar:hover{
   background-color: #dc3545;
}

.button-pesquisar{
   margin-left: 25px;
   text-transform: uppercase;
   width: 150px;
   height: 36px;
}

.button-pesquisar:hover{
   background-color: #007bff;
}

@media (max-width: 450px) {
   .buttons{
      text-align: center;
   }
   .button-limpar{
      width: 120px;
   }
   .button-pesquisar{
      width: 120px;
      margin-left: 4px;
   }
}

@media (min-width: 800px) {
   .button-limpar{
      width: 120px;
      margin-bottom: 4px;
   }
   .button-pesquisar{
      width: 120px;
      margin-left: 4px;
      margin-bottom: 4px;
   }
}
@media (min-width: 956px) {
   .button-limpar{
      width: 150px;
   }
   .button-pesquisar{
      width: 150px;
      margin-left: 25px;
   }
}
