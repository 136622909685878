.title-principal{
   font-family: 'Segoe UI';
   font-style: normal;
   font-weight: bold;
   font-size: 36px;
   line-height: 32px;
   letter-spacing: 0.15px;
   color: #17C6ED;
}

.subtitle{
   font-size: 24px;
   color: #BEC4C9;
   margin-top: 12px;
}

.icon-user{
   color: #BEC4C9; 
   position: absolute; 
   right: 95%;
   bottom: 10px;
}

.icon-password{
   position: absolute;
   right: 96%;
   bottom: 27px;
   font-size: 19px;
   margin-left: 2px;
   color:#BEC4C9; 
}

.icon-view-password{
   position: absolute;
   right: 2%;
   bottom: 27px;
   font-size: 19px;
   margin-left: 2px;
   color:#BEC4C9; 
}

.input{
   margin-top: 30px;
   background-color: #1b3d70;
   border: 0;
   border-bottom: 5px solid #BEC4C9;
   border-radius: 0;
   color: #BEC4C9;
   padding-left: 6%;
}
.btn-login{
   color: white;
}
.btn-login:hover{
   color: white;
   background-color: #0469FF;
}
.btn-voltar:hover{
  color: white;
  /* background-color: #2DBF79; */
}
.btn-login{
  background-color:#0469FF
}


@media (max-width: 450px) {
   .icon-user{
      right: 88%;
   }
   .icon-password{
      right: 90%;
   }
   .input{
      padding-left: 13%;
   }
}
@media (min-width: 769px) {
   .icon-user{
      right: 92%;
   }
   .icon-password{
      right: 94%;
   }
   .input{
      padding-left: 8%;
   }
   .button{
      min-width: 90px;
   }
}
@media (min-width: 990px) {
   .icon-user{
      right: 94%;
   }
   .icon-password{
      right: 95%;
   }
   .input{
      padding-left: 7%;
   }
}
@media (min-width: 1440px) {
   .icon-user{
      right: 96%;
   }
   .icon-password{
      right: 96.5%;
   }
   .input{
      padding-left: 5%;
   }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   padding-left:  50px;
   -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
   -webkit-transition-delay: 9999s;
}