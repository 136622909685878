@media(min-width: 800px){
   .card{
      margin-bottom: '4%';
      justify-content: 'space-evenly';
      margin-left: 250px;      
   }
   .index{
      margin-bottom: '4%';
      justify-content: 'space-evenly';
      margin-left: 250px; 
   }
}

@media(min-width: 992px){
   .card{
      margin-bottom: '4%';
      justify-content: 'space-evenly';
      margin-left: 0px;      
   }
   .index{
      margin-left: 0;
   }
}


@media(max-width: 450px){
   .row{
     margin-top: 4%;
     margin-bottom: 4%;
   }
   .MuiCard-root{
      margin: 30px 30px 0 8%!important;
   }
   .mobile{
      width: 320px!important;
   }
   .wrapper{
      background-color: #c0cbdc;
   }
}