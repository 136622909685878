.btn-danger:hover{
   background-color: #dc3545;
}
.btn-primary:hover{
   background-color: '#007DFF';
}

.btn-voltar{
   margin-right: 10px; 
   width: 150px; 
   height:36px; 
   border: none; 
   border-color: #bd2130; 
   border-radius: 4px; 
   text-transform: uppercase; 
   box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);

}